<template>
  <div class="bg-[#233445] py-14 hidden md:block">
    <div class="container">
      <div class="grid grid-cols-4">
        <div class="col-span-4">
          <div class="flex align-middle justify-center pb-3 text-5xl">
            <IconComponent class="text-white" icon="wrench" />
          </div>
          <div class="flex align-middle justify-center text-white text-2xl">
            {{ $t("personList.professions") }}
          </div>
        </div>
        <div v-if="!isSuperprofikCz" class="col-span-2">
          <section>
            <h1 class="text-2xl text-[#c7e6ff] pb-8">
              {{ $t("professionLinks.professionGroups") }}
            </h1>
          </section>
          <div class="columns pl-5">
            <div
              v-for="(array, index) in groups"
              :key="index"
              class="column is-half"
            >
              <div
                v-for="professionGroup in array"
                :key="`plpg${professionGroup.id}`"
                class="mb-2"
              >
                <NuxtLink
                  class="text-white hover:text-white mr-auto"
                  :to="
                    localePath({
                      name: 'professionsPage',
                      params: { professionGroup: professionGroup.seoName },
                    })
                  "
                >
                  {{ professionGroup.name }}
                </NuxtLink>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-span-2"
          :class="{
            'col-start-2': isSuperprofikCz,
          }"
        >
          <section>
            <h1 class="text-2xl text-[#c7e6ff] pb-8">
              {{ $t("professionLinks.popularProfessions") }}
            </h1>
          </section>
          <div class="grid grid-cols-2 pl-5">
            <div
              v-for="(professionArray, index) in professionsArrays"
              :key="index"
            >
              <div
                v-for="profession in professionArray"
                :key="`plp${profession.id}`"
                class="mb-2"
              >
                <NuxtLink
                  class="text-white hover:text-white mr-auto profession-link"
                  :to="
                    localePath({
                      name: 'professionPage',
                      params: { profession: profession.seoName },
                    })
                  "
                >
                  {{ profession.name }}
                </NuxtLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useBrand } from "~/composables/useBrand";

export default {
  setup() {
    const { isSuperprofikCz } = useBrand();
    return {
      isSuperprofikCz,
    };
  },
  computed: {
    city() {
      return this.$route.params.city;
    },
    professionGroups() {
      return this.$store.getters["data/professionGroups"];
    },
    popularProfessions() {
      return this.$store.getters["data/popularProfessions"];
    },
    groupsHalfSize() {
      return Math.ceil(this.professionGroups.length / 2) || 1;
    },
    professionsArrays() {
      return [
        this.popularProfessions.slice(0, 7),
        this.popularProfessions.slice(7),
      ];
    },
    groups() {
      return [
        this.professionGroups.slice(0, this.groupsHalfSize),
        this.professionGroups.slice(
          this.groupsHalfSize,
          this.professionGroups.length
        ),
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.profession-link {
  text-transform: capitalize;
}
</style>
