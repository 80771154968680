var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.profession)?_c('div',{staticClass:"container px-3 pt-3 pb-5"},[_c('BreadCrumbs',{attrs:{"links":_vm.getBreadCrumbs}}),_c('div',{staticClass:"hidden lg:block mt-10 mb-2.5 pl-2.5"},[_c('h2',{staticClass:"mt-2 mb-3 text-3xl font-semibold text-primary lg:text-4xl first-letter:capitalize"},[_vm._v(" Ingyenes árajánlatkérés ")]),_c('h1',{staticClass:"mt-2 mb-3 text-xl font-semibold text-primary lg:text-2xl first-letter:capitalize"},[_vm._v(" "+_vm._s(_vm.profession.theperson)+_vm._s(_vm.profession.theperson.endsWith("szakember") ? "ektől" : " szakemberektől")+" ")]),_c('p',{staticClass:"font-semibold text-blog-primary"},[_vm._v(" Válaszd ki a városod és találd meg a számodra legmegfelelőbb "+_vm._s(_vm.profession.objective)+"! ")])]),_c('div',{staticClass:"lg:hidden headingBlock"},[_c('h1',{staticClass:"mt-2 mb-3 font-extrabold title h1 first-letter-is-capital"},[_vm._v(" "+_vm._s(_vm.profession.plural)+" ")]),_c('p',[_vm._v(" Válaszd ki a városod és találd meg a számodra legmegfelelőbb "+_vm._s(_vm.profession.objective)+"! ")])]),_c('div',{staticClass:"browseBlockBase"},[_c('div',{staticClass:"contentHeader"},[_c('div',{staticClass:"cityButtons"},_vm._l((_vm.firstFewCitiesWithPersonCount),function(city,index){return _c('div',{key:index,staticClass:"cityButton",class:{
              active: index === _vm.selectedCityIndex,
            },on:{"click":function($event){return _vm.changeCity(city, index)}}},[_vm._v(" "+_vm._s(city.name)+" ")])}),0),_c('div',{staticClass:"search"},[_c('AutocompleteCity',{attrs:{"placeholder":_vm.$t('citySelector.cities.placeholder')},on:{"select":function($event){return _vm.changeCity($event, -1)}}})],1)]),_c('div',{staticClass:"contentBody"},[(_vm.isLoading)?_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"peopleListLoader",attrs:{"data-src":require("assets/img/loader.svg"),"width":"100"}}):_vm._e(),(_vm.peopleByCity.length > 0)?_c('PeopleSwiper',{staticClass:"person-swiper",class:{ loadingLook: _vm.isLoading }},_vm._l((_vm.peopleByCity),function(person){return _c('CardPerson',{key:person.id,staticClass:"person-swiper-card",attrs:{"person":person},on:{"select":function($event){return _vm.onClickPersonCard(person)},"requestTender":function($event){return _vm.onRequestTender({ person, source: 'personCard' })}}})}),1):_vm._e(),_c('NuxtLink',{staticClass:"inline-block w-full md:w-auto p-2 rounded-[4px] border-transparent text-white hover:text-white bg-primary hover:bg-primary-dark mt-2 whitespace-normal",attrs:{"to":_vm.localePath({
              name: 'professionals',
              params: {
                profession: _vm.profession.seoName,
                city: _vm.selectedCity.seoName,
              },
            })}},[_vm._v(" Mutasd az összes "),_c('span',{staticStyle:{"text-transform":"lowercase"}},[_vm._v(_vm._s(_vm.selectedCity.name)+"i")]),_vm._v(" "+_vm._s(_vm.profession.objective)+" ")])],1)]),_c('div',{staticClass:"mb-5 browseCities"},[_vm._m(0),_c('CityTabs',{attrs:{"counties":_vm.counties,"profession":_vm.profession,"city-person-count":_vm.cityPersonCount}})],1),(_vm.firstFewProfessionTasks.length)?_c('div',{staticClass:"headingBlock"},[_c('h1',{staticClass:"mt-2 mb-3 font-extrabold title h1 first-letter-is-capital"},[_vm._v(" Legnépszerűbb feladatok és árak ")]),_c('p',[_vm._v(" Válaszd ki, milyen feladathoz keresel segítséget, böngéssz azon szakemberek között, akik vállaljàk és tájékozódj az árakról! ")])]):_vm._e(),(_vm.firstFewProfessionTasks.length)?_c('div',{staticClass:"browseBlockBase"},[_c('div',{staticClass:"contentHeader"},[_c('div',{staticClass:"ptTabs"},_vm._l((_vm.firstFewProfessionTasks),function(professionTask,index){return _c('div',{key:index,staticClass:"ptTab",class:{
              active: index === _vm.selectedProfessionTaskIndex,
            },on:{"click":function($event){return _vm.changeProfessionTask(professionTask, index)}}},[_vm._v(" "+_vm._s(professionTask.name)+" ")])}),0),_c('div',{staticClass:"search"},[_c('AutocompleteProfessionTask',{attrs:{"placeholder":_vm.$t('professionTaskSelector.placeholder'),"options":_vm.professionTasks},on:{"select":function($event){return _vm.changeProfessionTask($event, -1)}}})],1)]),(_vm.selectedProfessionTask)?_c('div',{staticClass:"contentBody"},[_c('h2',[_vm._v("Néhány "+_vm._s(_vm.selectedProfessionTask.name)+" ár a rendszerünkből")]),(_vm.isLoading)?_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"peopleListLoader",attrs:{"data-src":require("assets/img/loader.svg"),"width":"100"}}):_vm._e(),_c('NuxtLink',{attrs:{"to":_vm.localePath({
              name: 'prices',
              params: {
                professionTask: _vm.selectedProfessionTask.seoName,
              },
            })}},[(_vm.peopleByProfessionTask.length > 0)?_c('PeopleSwiper',{staticClass:"person-swiper",class:{ loadingLook: _vm.isLoading }},_vm._l((_vm.peopleByProfessionTask),function(person){return _c('PeopleSwiperProfessionTaskPersonInset',{key:person.id,attrs:{"profession-task":_vm.selectedProfessionTask,"person":person}})}),1):_vm._e()],1),(_vm.peopleByProfessionTask.length == 0)?_c('div',{staticClass:"emptyView"},[_vm._v(" Sajnos jelenleg egy szakembert sem tudunk mutatni aki "),_c('b',[_vm._v(_vm._s(_vm.selectedProfessionTask.name))]),_vm._v("-al foglalkozik ... ")]):_vm._e(),_c('a',{staticClass:"button is-info",attrs:{"href":`${_vm.$helpers.topDomain}/arak/${_vm.selectedProfessionTask.seoName}`}},[_vm._v(" Mutasd az összes "+_vm._s(_vm.selectedProfessionTask.name)+" árat ")])],1):_vm._e()]):_vm._e(),(_vm.betweenHorizontalAndVerticalPersons.length > 0)?_c('div',{staticClass:"adviceBlock"},[_c('div',{staticClass:"sideBlocks"},[_c('ProfessionPriceRangesBox',{staticClass:"mb-4",attrs:{"profession":_vm.profession,"profession-tasks":_vm.professionTasks}}),_c('div',{staticClass:"floater"},[_c('TableOfContents',{attrs:{"articles":_vm.betweenHorizontalAndVerticalPersons}}),_vm._l((_vm.priceCalculators),function(priceCalculator,index){return [_c('SideBlock',{key:index,attrs:{"title":_vm.$t('priceCalculator.fallbackTitle')}},[_vm._v(" Számíts ki egy példa árkalkulációt "),_c('NuxtLink',{attrs:{"to":priceCalculator.path,"target":"_blank"}},[_vm._v(" "+_vm._s(priceCalculator.name)+" árkalkulátorunkkal ")]),_vm._v("! ")],1)]}),_c('QuickOfferBox',{on:{"requestTender":function($event){return _vm.onRequestTender({ source: 'quickOffer', description: $event })}}}),(_vm.inspirations.length)?_c('SideBlock',{attrs:{"title":_vm.$t('professionPage.relatedInspirations')}},[_c('ol',_vm._l((_vm.inspirations),function(inspiration,index){return _c('li',{key:index},[_c('NuxtLink',{attrs:{"to":_vm.localePath({
                      name: 'inspirationPage',
                      params: { inspirationSeoName: inspiration.seoName },
                    })}},[_vm._v(" "+_vm._s(inspiration.name)+" ")])],1)}),0)]):_vm._e()],2)],1),_c('div',{staticClass:"advices"},[_c('TableOfContents',{staticClass:"is-hidden-desktop",attrs:{"articles":_vm.betweenHorizontalAndVerticalPersons}}),_vm._l((_vm.betweenHorizontalAndVerticalPersons),function(adviceBlock,index){return _c('div',{key:index,staticClass:"advice",attrs:{"id":_vm.$helpers.stringToSlug(adviceBlock.title)}},[_c('h1',{staticClass:"adviceBlockTitle"},[_vm._v(" "+_vm._s(adviceBlock.title)+" ")]),_c('div',{directives:[{name:"dangerouslySetInnerHTML",rawName:"v-dangerouslySetInnerHTML",value:(_vm.$md.render(adviceBlock.content)),expression:"$md.render(adviceBlock.content)"}],staticClass:"markdown"})])})],2)]):_vm._e(),_c('BlockTender',{attrs:{"text-input":""},on:{"requestTender":function($event){return _vm.onRequestTender({ source: 'banner-block', description: $event })}}}),_c('br'),(_vm.isLoading)?_c('div',{staticClass:"columns is-multiline"},_vm._l((16),function(i){return _c('content-placeholders',{key:i,staticClass:"mb-20 column is-half",attrs:{"lines":1,"rounded":true}},[_c('content-placeholders-heading',{attrs:{"img":true}}),_c('content-placeholders-text')],1)}),1):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"text-center"},[_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"m-auto",staticStyle:{"height":"5.5rem"},attrs:{"data-src":require("assets/img/loader.svg"),"width":"100"}})]):_vm._e()],1):_vm._e(),_c('ProfessionLinks')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"headingBlock"},[_c('h1',{staticClass:"text-sm font-extrabold title h1",staticStyle:{"font-size":"24px"}},[_vm._v(" Nagyobb települések ")])])
}]

export { render, staticRenderFns }