<template>
  <SideBlock
    class="ProfessionPriceRangesBox"
    :title="
      $t('profPriceRangesBox.title', {
        professionName: $helpers.capitalize(profession.name),
      })
    "
  >
    <div class="grid gap-1">
      <div
        v-for="(task, index) in professionTasksWithPrice"
        :key="index"
        class="flex justify-between"
      >
        <NuxtLink
          :to="
            localePath({
              name: 'prices',
              params: { professionTask: task.seoName },
            })
          "
          class="capitalize"
        >
          {{ task.name }}
        </NuxtLink>
        <div class="font-bold text-blog-primary">
          {{ $n(task.minimumPrice, "currency") }} / {{ task.unit }}
        </div>
      </div>
    </div>
    <p class="my-2">
      {{ $t("profPriceRangesBox.sideBlock") }}
    </p>
  </SideBlock>
</template>

<script>
import SideBlock from "~/components/_refactored/mainpage/SideBlock.vue";

export default {
  components: { SideBlock },
  props: {
    professionTasks: {
      type: Array,
      required: true,
    },
    profession: {
      type: Object,
      required: true,
    },
  },
  computed: {
    professionTasksWithPrice() {
      return this.professionTasks.filter((task) => task.minimumPrice);
    },
  },
};
</script>
