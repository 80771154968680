var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.person)?_c('NuxtLink',{staticClass:"min-w-[250px] max-w-[350px] bg-white p-4 rounded-lg flex-grow relative text-typo-primary",attrs:{"to":_vm.localePath({
      name: 'professionalPage',
      params: {
        professionalSeoName: _vm.person.seoName,
      },
    })}},[_c('div',{staticClass:"flex"},[_c('PersonPictureSet',{staticClass:"rounded absolute top-[-0.5rem] overflow-hidden",attrs:{"person-id":_vm.person.id,"width":"70","height":"70","data-not-lazy":""}}),_c('div',{staticClass:"mb-2 text-right grow",class:[
        {
          invisible: !_vm.hasNonZeroPrice,
        },
      ]},[_c('div',{staticClass:"text-xl font-extrabold text-teal-400"},[_vm._v(" "+_vm._s(_vm.$n(_vm.person?.price?.price ?? 0, "currency"))+" ")]),_c('div',{staticClass:"text-xs text-blog-primary"},[_vm._v(" "+_vm._s(_vm.professionTask.unit)+" ár ")])])],1),_c('div',{staticClass:"text-base font-bold"},[_vm._v(" "+_vm._s(_vm.person.name)+" ")]),_c('div',{staticClass:"mb-2 text-xs font-extrabold opacity-50"},[_vm._v(" "+_vm._s(_vm.person.city)+" ")]),_c('div',{staticClass:"overflow-hidden text-sm font-semibold line-clamp-2"},[_vm._v(" "+_vm._s(_vm.person?.price?.description ?? _vm.person.memo)+" ")])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }