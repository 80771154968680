<template>
  <SideBlock class="TableOfContents" title="Tartalomjegyzék">
    <ul>
      <li v-for="(article, index) in articles" :key="index">
        <a :href="`#${$helpers.stringToSlug(article.title)}`">
          <IconComponent icon="arrow-right" class="inline" />
          {{ article.title }}
        </a>
      </li>
    </ul>
  </SideBlock>
</template>

<script>
import SideBlock from "~/components/_refactored/mainpage/SideBlock.vue";

export default {
  components: { SideBlock },
  props: {
    articles: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
