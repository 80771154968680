<template>
  <nav
    class="flex items-center font-semibold flex-wrap text-typo-secondary text-xs lg:text-lg"
    aria-label="breadcrumb"
  >
    <ol class="flex flex-row items-center flex-wrap">
      <li>
        <a :href="$helpers.topDomain">
          <IconComponent
            class="whitespace-nowrap text-typo-secondary capitalize"
            icon="home"
          />
        </a>
      </li>
      <li
        v-for="(link, index) in links"
        :key="index"
        class="flex items-center gap-1 ml-1"
        @click="callLinkCallback(link)"
      >
        <IconComponent class="!text-xs !opacity-20" icon="angle-double-right" />

        <!-- If its an internal link -->
        <div
          v-if="link.links && Array.isArray(link.links)"
          class="flex capitalize"
        >
          <div v-for="(innerLink, index2) in link.links" :key="index2">
            <NuxtLink
              v-if="innerLink.page"
              :to="innerLink.page"
              class="whitespace-nowrap text-typo-secondary capitalize"
            >
              {{ innerLink.name }}
            </NuxtLink>
            <a
              v-else-if="innerLink.url"
              :href="innerLink.url"
              class="whitespace-nowrap text-typo-secondary capitalize"
            >
              {{ innerLink.name }}
            </a>
            {{ index2 !== link.links.length - 1 ? "&nbsp;/&nbsp;" : "" }}
          </div>
        </div>
        <div v-else-if="link.page">
          <NuxtLink
            v-if="link.page"
            :to="link.page"
            class="whitespace-nowrap text-typo-secondary capitalize"
          >
            {{ link.name }}
          </NuxtLink>
        </div>

        <!-- If its an external or absolute link -->
        <a
          v-else-if="link.url"
          :href="link.url"
          target="_blank"
          class="whitespace-nowrap text-typo-secondary capitalize"
        >
          {{ link.name }}
        </a>

        <!-- If its not a link -->
        <div v-else class="whitespace-nowrap text-typo-secondary capitalize">
          {{ link.name }}
        </div>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
  methods: {
    callLinkCallback(link) {
      if (link.callback) {
        link.callback();
      }
    },
  },
};
</script>
