<template>
  <NuxtLink
    v-if="person"
    class="min-w-[250px] max-w-[350px] bg-white p-4 rounded-lg flex-grow relative text-typo-primary"
    :to="
      localePath({
        name: 'professionalPage',
        params: {
          professionalSeoName: person.seoName,
        },
      })
    "
  >
    <div class="flex">
      <PersonPictureSet
        :person-id="person.id"
        class="rounded absolute top-[-0.5rem] overflow-hidden"
        width="70"
        height="70"
        data-not-lazy
      />
      <div
        class="mb-2 text-right grow"
        :class="[
          {
            invisible: !hasNonZeroPrice,
          },
        ]"
      >
        <div class="text-xl font-extrabold text-teal-400">
          {{ $n(person?.price?.price ?? 0, "currency") }}
        </div>
        <div class="text-xs text-blog-primary">
          <!-- / FT -->
          {{ professionTask.unit }} ár
        </div>
      </div>
    </div>
    <div class="text-base font-bold">
      {{ person.name }}
    </div>
    <div class="mb-2 text-xs font-extrabold opacity-50">
      {{ person.city }}
    </div>
    <div class="overflow-hidden text-sm font-semibold line-clamp-2">
      {{ person?.price?.description ?? person.memo }}
    </div>
  </NuxtLink>
</template>

<script>
import PersonPictureSet from "~/components/PersonPictureSet.vue";

export default {
  components: {
    PersonPictureSet,
  },
  props: {
    professionTask: {
      type: Object,
      required: true,
    },
    person: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasNonZeroPrice() {
      return this.person?.price?.price > 0;
    },
  },
};
</script>
