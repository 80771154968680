<template>
  <div class="SideBlock w-full p-4 bg-gray-200 rounded-md">
    <p class="text-2xl text-primary font-bold">
      {{ title }}
    </p>
    <div class="text-sm pt-2">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "SideBlock",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>
