<template>
  <div>
    <!-- Szakma főoldal -->
    <div v-if="profession" class="container px-3 pt-3 pb-5">
      <!-- Header -->
      <BreadCrumbs :links="getBreadCrumbs" />

      <div class="hidden lg:block mt-10 mb-2.5 pl-2.5">
        <h2
          class="mt-2 mb-3 text-3xl font-semibold text-primary lg:text-4xl first-letter:capitalize"
        >
          Ingyenes árajánlatkérés
        </h2>
        <h1
          class="mt-2 mb-3 text-xl font-semibold text-primary lg:text-2xl first-letter:capitalize"
        >
          {{ profession.theperson
          }}{{
            profession.theperson.endsWith("szakember")
              ? "ektől"
              : " szakemberektől"
          }}
        </h1>
        <p class="font-semibold text-blog-primary">
          Válaszd ki a városod és találd meg a számodra legmegfelelőbb
          {{ profession.objective }}!
        </p>
      </div>
      <div class="lg:hidden headingBlock">
        <h1 class="mt-2 mb-3 font-extrabold title h1 first-letter-is-capital">
          {{ profession.plural }}
        </h1>
        <p>
          Válaszd ki a városod és találd meg a számodra legmegfelelőbb
          {{ profession.objective }}!
        </p>
      </div>

      <div class="browseBlockBase">
        <div class="contentHeader">
          <div class="cityButtons">
            <div
              v-for="(city, index) in firstFewCitiesWithPersonCount"
              :key="index"
              class="cityButton"
              :class="{
                active: index === selectedCityIndex,
              }"
              @click="changeCity(city, index)"
            >
              {{ city.name }}
            </div>
          </div>
          <div class="search">
            <AutocompleteCity
              :placeholder="$t('citySelector.cities.placeholder')"
              @select="changeCity($event, -1)"
            />
          </div>
        </div>

        <div class="contentBody">
          <img
            v-if="isLoading"
            v-lazy-load data-src="~/assets/img/loader.svg"
            width="100"
            class="peopleListLoader"
          />
          <PeopleSwiper
            v-if="peopleByCity.length > 0"
            :class="{ loadingLook: isLoading }"
            class="person-swiper"
          >
            <CardPerson
              v-for="person in peopleByCity"
              :key="person.id"
              class="person-swiper-card"
              :person="person"
              @select="onClickPersonCard(person)"
              @requestTender="onRequestTender({ person, source: 'personCard' })"
            />
          </PeopleSwiper>

          <NuxtLink
            :to="
              localePath({
                name: 'professionals',
                params: {
                  profession: profession.seoName,
                  city: selectedCity.seoName,
                },
              })
            "
            class="inline-block w-full md:w-auto p-2 rounded-[4px] border-transparent text-white hover:text-white bg-primary hover:bg-primary-dark mt-2 whitespace-normal"
          >
            Mutasd az összes
            <span style="text-transform: lowercase"
              >{{ selectedCity.name }}i</span
            >
            {{ profession.objective }}
          </NuxtLink>
        </div>
      </div>

      <div class="mb-5 browseCities">
        <div class="headingBlock">
          <h1 class="text-sm font-extrabold title h1" style="font-size: 24px">
            Nagyobb települések
          </h1>
        </div>
        <CityTabs
          :counties="counties"
          :profession="profession"
          :city-person-count="cityPersonCount"
        />
      </div>

      <div v-if="firstFewProfessionTasks.length" class="headingBlock">
        <h1 class="mt-2 mb-3 font-extrabold title h1 first-letter-is-capital">
          Legnépszerűbb feladatok és árak
        </h1>
        <p>
          Válaszd ki, milyen feladathoz keresel segítséget, böngéssz azon
          szakemberek között, akik vállaljàk és tájékozódj az árakról!
        </p>
      </div>

      <div v-if="firstFewProfessionTasks.length" class="browseBlockBase">
        <div class="contentHeader">
          <div class="ptTabs">
            <div
              v-for="(professionTask, index) in firstFewProfessionTasks"
              :key="index"
              class="ptTab"
              :class="{
                active: index === selectedProfessionTaskIndex,
              }"
              @click="changeProfessionTask(professionTask, index)"
            >
              {{ professionTask.name }}
            </div>
          </div>
          <div class="search">
            <AutocompleteProfessionTask
              :placeholder="$t('professionTaskSelector.placeholder')"
              :options="professionTasks"
              @select="changeProfessionTask($event, -1)"
            />
          </div>
        </div>

        <div v-if="selectedProfessionTask" class="contentBody">
          <h2>Néhány {{ selectedProfessionTask.name }} ár a rendszerünkből</h2>
          <img
            v-if="isLoading"
            v-lazy-load data-src="~/assets/img/loader.svg"
            width="100"
            class="peopleListLoader"
          />
          <NuxtLink
            :to="
              localePath({
                name: 'prices',
                params: {
                  professionTask: selectedProfessionTask.seoName,
                },
              })
            "
          >
            <PeopleSwiper
              v-if="peopleByProfessionTask.length > 0"
              :class="{ loadingLook: isLoading }"
              class="person-swiper"
            >
              <PeopleSwiperProfessionTaskPersonInset
                v-for="person in peopleByProfessionTask"
                :key="person.id"
                :profession-task="selectedProfessionTask"
                :person="person"
              />
            </PeopleSwiper>
          </NuxtLink>

          <div v-if="peopleByProfessionTask.length == 0" class="emptyView">
            Sajnos jelenleg egy szakembert sem tudunk mutatni aki
            <b>{{ selectedProfessionTask.name }}</b
            >-al foglalkozik ...
          </div>
          <a
            :href="`${$helpers.topDomain}/arak/${selectedProfessionTask.seoName}`"
            class="button is-info"
          >
            Mutasd az összes {{ selectedProfessionTask.name }} árat
          </a>
        </div>
      </div>
      <div
        v-if="betweenHorizontalAndVerticalPersons.length > 0"
        class="adviceBlock"
      >
        <div class="sideBlocks">
          <ProfessionPriceRangesBox
            :profession="profession"
            :profession-tasks="professionTasks"
            class="mb-4"
          />
          <div class="floater">
            <TableOfContents :articles="betweenHorizontalAndVerticalPersons" />
            <template v-for="(priceCalculator, index) in priceCalculators">
              <SideBlock
                :key="index"
                :title="$t('priceCalculator.fallbackTitle')"
              >
                Számíts ki egy példa árkalkulációt
                <NuxtLink :to="priceCalculator.path" target="_blank">
                  {{ priceCalculator.name }} árkalkulátorunkkal </NuxtLink
                >!
              </SideBlock>
            </template>
            <QuickOfferBox
              @requestTender="
                onRequestTender({ source: 'quickOffer', description: $event })
              "
            />
            <SideBlock
              v-if="inspirations.length"
              :title="$t('professionPage.relatedInspirations')"
            >
              <ol>
                <li v-for="(inspiration, index) in inspirations" :key="index">
                  <NuxtLink
                    :to="
                      localePath({
                        name: 'inspirationPage',
                        params: { inspirationSeoName: inspiration.seoName },
                      })
                    "
                  >
                    {{ inspiration.name }}
                  </NuxtLink>
                </li>
              </ol>
            </SideBlock>
          </div>
        </div>
        <div class="advices">
          <TableOfContents
            class="is-hidden-desktop"
            :articles="betweenHorizontalAndVerticalPersons"
          />

          <div
            v-for="(adviceBlock, index) in betweenHorizontalAndVerticalPersons"
            :id="$helpers.stringToSlug(adviceBlock.title)"
            :key="index"
            class="advice"
          >
            <h1 class="adviceBlockTitle">
              <!-- TODO: maybe translate the suggestions also -->
              {{ adviceBlock.title }}
            </h1>
            <div
              v-dangerouslySetInnerHTML="$md.render(adviceBlock.content)"
              class="markdown"
            />
          </div>
        </div>
      </div>

      <BlockTender
        text-input
        @requestTender="
          onRequestTender({ source: 'banner-block', description: $event })
        "
      />

      <br />

      <div v-if="isLoading" class="columns is-multiline">
        <content-placeholders
          v-for="i in 16"
          :key="i"
          :lines="1"
          :rounded="true"
          class="mb-20 column is-half"
        >
          <content-placeholders-heading :img="true" />
          <content-placeholders-text />
        </content-placeholders>
      </div>

      <div v-if="isLoading" class="text-center">
        <img
          v-lazy-load data-src="~/assets/img/loader.svg"
          width="100"
          class="m-auto"
          style="height: 5.5rem"
        />
      </div>
    </div>
    <!-- TODO 404 page -->
    <ProfessionLinks />
  </div>
</template>

<router>
{
  name: 'professionPage'
}
</router>

<script>
import ProfessionPriceRangesBox from "~/components/_refactored/profession/ProfessionPriceRangesBox.vue";
import MAIN_PAGE_QUERY_PEOPLE from "~/graphql/mainPageSSRPeople.graphql";
import PEOPLE_BY_CITY_AND_PROFESSION from "~/graphql/peopleByCityAndProfession.graphql";
import ProfessionLinks from "~/components/people/ProfessionLinks.vue";
import TableOfContents from "~/components/professionTaskPage/tableOfContents";
import QuickOfferBox from "~/components/professionTaskPage/quickOfferBox";
import BreadCrumbs from "~/components/_refactored/BreadCrumbs.vue";
import PeopleSwiper from "~/components/PeopleSwiper.vue";
import PeopleSwiperProfessionTaskPersonInset from "~/components/PeopleSwiperProfessionTaskPersonInset.vue";
import AutocompleteProfessionTask from "~/components/AutocompleteProfessionTask.vue";
import CardPerson from "~/components/people/CardPerson.vue";
import AutocompleteCity from "~/components/AutocompleteCity.vue";
import CityTabs from "@/components/mainpage/CityTabs.vue";
import { useRequestTender } from "~/composables/requestTender";

import SideBlock from "~/components/_refactored/mainpage/SideBlock.vue";
import BlockTender from "~/components/_refactored/blocks/BlockTender.vue";
import useRestClient from "~/helpers/restClient";
import { useNewTender } from "~/composables/tender/useNewTender";

export default {
  name: "ProfessionPage",
  components: {
    BlockTender,
    SideBlock,
    ProfessionLinks,
    PeopleSwiper,
    PeopleSwiperProfessionTaskPersonInset,
    TableOfContents,
    QuickOfferBox,
    BreadCrumbs,
    AutocompleteProfessionTask,
    CardPerson,
    AutocompleteCity,
    ProfessionPriceRangesBox,
    CityTabs,
  },
  layout: "DefaultLayout",
  setup() {
    const newTender = useNewTender();
    newTender.showFloater();
    return {
      ...useRequestTender(),
    };
  },
  async asyncData(context) {
    await context.store.dispatch("layout/setShowSlogan", true);

    const professionSeoName = context.route.params.profession;
    const restClient = useRestClient(context.$api);

    try {
      // TODO add query to auto select city and professionTasks
      const [
        profession,
        professionTasksByProfSeoName,
        counties,
        cities,
        peopleCount,
        inspirationsResponse,
      ] = await Promise.all([
        restClient.professions.bySeoName(professionSeoName),
        restClient.professionTasks.popularByProfessionSeoName({
          professionSeoName,
        }),
        restClient.counties.get(),
        restClient.cities.get({
          minPopulation: 1,
          orderBy: "population",
          order: "desc",
        }),
        restClient.people.count({
          professionSeoName,
        }),
        restClient.inspirations.get({
          professionSeoName,
        }),
      ]);

      if (!profession) {
        return context.error({ statusCode: 404, message: "nem letezik" });
      }

      const professionTasks = professionTasksByProfSeoName;
      const selectedProfessionTask = professionTasks?.[0];
      const cityPersonCount = cities;
      const selectedCity = cityPersonCount[0];

      await Promise.all([
        context.store.dispatch("listPage/setProfession", profession),
        context.store.dispatch("listPage/setProfessionTask", null),
      ]);

      let peopleByCity = [];
      let peopleByProfessionTask = [];

      // TODO unfuck this
      if (!selectedProfessionTask && selectedCity) {
        const responsePeople = await context.$query(
          PEOPLE_BY_CITY_AND_PROFESSION,
          {
            professionSeoName,
            citySeoName: selectedCity.seoName,
            limit: 8,
            newOrder: true,
          }
        );
        peopleByCity = responsePeople.peopleByCityAndProfession.people;
      } else {
        const responsePeople = await context.$query(MAIN_PAGE_QUERY_PEOPLE, {
          professionSeoName,
          professionTaskSeoName: selectedProfessionTask.seoName,
          citySeoName: selectedCity.seoName,
          limit: 8,
          newOrder: true,
        });
        peopleByProfessionTask =
          responsePeople.peopleByCityAndProfessionTask.people;
        peopleByCity = responsePeople.peopleByCityAndProfession.people;
      }

      return {
        professionSeoName,
        profession,
        professionTasks,
        selectedProfessionTask,
        peopleByProfessionTask,
        peopleByCity,
        cityPersonCount,
        counties,
        selectedCity,
        activePeopleCount: peopleCount.activeCount,
        peopleCount: peopleCount.count,
        inspirations: inspirationsResponse.inspirations,
      };
    } catch (err) {
      console.error("failed to load profession page", err);
      context.$sentry.captureException(JSON.stringify(err), {
        tags: {
          profession_page: "error",
        },
      });
    }
  },
  data() {
    return {
      selectedProfessionTaskIndex: 0,
      selectedCityIndex: 0,
      professionalCount: 71665,
      isLoading: false,
      loadCount: 1,
    };
  },
  head() {
    if (this.profession) {
      const title = this.$t("professionPage.title", {
        professionName: this.$helpers.capitalize(this.profession.name),
      });
      return {
        title,
        meta: [
          {
            hid: "og:title",
            property: "og:title",
            content: title,
          },
          {
            hid: "og:url",
            property: "og:url",
            content: `${this.$helpers.topDomain}${this.$route.fullPath}`,
          },
          {
            hid: "og:image",
            property: "og:image",
            content: "/images/common/szaki.png",
          },
          {
            hid: "description",
            name: "description",
            content: this.$i18n.t("seo.mainPage.description", {
              profession: this.profession.seoName,
            }),
          },
          {
            hid: "og:description",
            property: "og:description",
            content: this.$i18n.t("seo.mainPage.description", {
              profession: this.profession.seoName,
            }),
          },
        ],
      };
    }

    return {};
  },
  computed: {
    priceCalculators() {
      return this.professionTasks
        .filter((task) => task.priceCalculator?.id)
        .map((task) => {
          return {
            name: task.name,
            path: this.localePath({
              name: "priceCalculatorPage",
              params: {
                professionTask: task.seoName,
              },
            }),
          };
        });
    },
    heroTitle() {
      return this.profession.name;
    },
    getBreadCrumbs() {
      const links = [];
      if (this.profession?.name) {
        if (this.profession.professionGroup) {
          const professionsPagePath = this.localePath({
            name: "professionsPage",
            params: {
              professionGroup: this.profession.professionGroup.seoName,
            },
          });
          if (this.profession.urlType !== "subdomain") {
            links.push({
              name: this.profession.professionGroup.name,
              page: professionsPagePath,
            });
          } else {
            links.push({
              name: this.profession.professionGroup.name,
              url: `${this.$helpers.topDomain}${professionsPagePath}`,
            });
          }
        }
        links.push({
          name: this.profession.name,
          link: "",
        });
      }
      return links;
    },
    firstFewProfessionTasks() {
      return (
        this.professionTasks?.slice(
          0,
          Math.min(this.professionTasks.length, 5)
        ) ?? []
      );
    },
    firstFewCitiesWithPersonCount() {
      return (
        this.cityPersonCount?.slice(
          0,
          Math.min(this.cityPersonCount.length, 7)
        ) ?? []
      );
    },
    hasSuggestionContent() {
      return (
        this.profession.suggestionContent &&
        this.profession.suggestionContent.filter(
          (content) => content.type === "content"
        ).length > 0
      );
    },
    betweenHorizontalAndVerticalPersons() {
      if (this.hasSuggestionContent) {
        const horizontalIndex = this.profession.suggestionContent.findIndex(
          (content) => content.type === "horizontal_list_of_tradesman"
        );
        const verticalIndex = this.profession.suggestionContent.findIndex(
          (content) => content.type === "vertical_list_of_tradesman"
        );
        if (horizontalIndex > -1 && verticalIndex > -1) {
          return this.profession.suggestionContent
            .slice(horizontalIndex, verticalIndex)
            .filter((c) => c.type === "content");
        } else if (horizontalIndex === -1 && verticalIndex > -1) {
          return this.profession.suggestionContent
            .slice(0, verticalIndex)
            .filter((c) => c.type === "content");
        } else if (verticalIndex === -1 && horizontalIndex > -1) {
          return this.profession.suggestionContent
            .slice(horizontalIndex + 1)
            .filter((c) => c.type === "content");
        } else {
          return this.profession.suggestionContent.filter(
            (c) => c.type === "content"
          );
        }
      } else {
        return [];
      }
    },
    peopleIds() {
      return this.peopleByCity?.map((p) => p.id) ?? [];
    },
  },
  mounted() {
    this.seoTrackViewPage();
    this.$trackers.pageview({
      pagename: this.$trackers.pageNames.PROFESSIONS_PAGE,
      seoName: this.$route.params.profession,
    });
  },
  beforeDestroy() {
    this.$trackers.pageleave({
      pagename: this.$trackers.pageNames.PROFESSIONS_PAGE,
      seoName: this.$route.params.profession,
    });
  },
  methods: {
    changeCity(city, index) {
      if (city) {
        this.selectedCity = city;
        this.selectedCityIndex = index;
        this.refetchPeople();
      } else {
        this.selectedCity = this.firstFewCitiesWithPersonCount[0];
        this.selectedCityIndex = 0;
        this.refetchPeople();
      }
    },
    changeProfessionTask(professionTask, index) {
      if (professionTask) {
        this.selectedProfessionTask = professionTask;
        this.selectedProfessionTaskIndex = index;
        this.refetchPeople();
      } else {
        this.selectedProfessionTask = this.firstFewProfessionTasks[0];
        this.selectedProfessionTaskIndex = 0;
        this.refetchPeople();
      }
    },
    async refetchPeople() {
      this.isLoading = true;
      if (!this.selectedProfessionTask) {
        const responsePeople = await this.$query(
          PEOPLE_BY_CITY_AND_PROFESSION,
          {
            professionSeoName: this.profession.seoName,
            citySeoName: this.selectedCity.seoName,
            limit: 8,
          }
        );
        this.peopleByCity = responsePeople.peopleByCityAndProfession.people;
      } else {
        const responsePeople = await this.$query(MAIN_PAGE_QUERY_PEOPLE, {
          professionSeoName: this.profession.seoName,
          professionTaskSeoName: this.selectedProfessionTask.seoName,
          citySeoName: this.selectedCity.seoName,
          limit: 8,
        });
        this.peopleByProfessionTask =
          responsePeople.peopleByCityAndProfessionTask.people;
        this.peopleByCity = responsePeople.peopleByCityAndProfession.people;
      }
      this.isLoading = false;
    },
    setCity(event) {
      if (event) {
        this.$router.push({
          path: `${this.$route.path}/${event.seoName}`,
        });
      }
    },
    setProfessionTask(event) {
      if (event) {
        this.$router.push({
          path: `/arak/${event.seoName}`,
        });
      }
    },
    onRequestTender({ person, source, description } = {}) {
      this.requestTender({
        person,
        city: this.city,
        profession: this.profession,
        source,
        description,
      });
    },
    trackOpenPersonProfile(person) {
      this.$track.customEvent({ ecommerce: null });
      this.$track.customEvent({
        event: "select_item",
        city_id: this.city?.id,
        profession_id: this.profession?.id,
        profession_task_id: this.professionTask?.id,
        ecommerce: {
          items: [
            {
              index: this.peopleIds.indexOf(person.id),
              item_id: person.id,
              item_name: person.name,
              item_list_name: `${this.profession?.name} ${this.city?.name}`,
              item_list_id: `${this.city?.seoName}#${this.profession?.seoName}`,
            },
          ],
        },
      });
    },
    onClickPersonCard(person) {
      this.trackOpenPersonProfile(person);
      window.open(
        this.localePath({
          name: "professionalPage",
          params: { professionalSeoName: person.seoName },
        })
      );
    },
    seoTrackViewPage() {
      if (!this.profession) {
        return;
      }
      this.$track.customEvent({
        event: "professionListPage",
        profession: this.profession.seoName, // szakma
        profiles: this.peopleCount, // Hány szaki van összesen?,
        activeProfiles: this.activePeopleCount, // Hány szaki van összesen?
        hasContent: this.hasSuggestionContent, // van szöveges tartalom? | boolean -> 1 = van 0 = nincs
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@use "styles/_all-members" as global;

.person-swiper {
  // Swiper holder overrides
  margin-bottom: 10px;
}

.loadingLook {
  opacity: 0.1;
  pointer-events: none;
}

.peopleListLoader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
}

.browseCities {
  margin-top: 40px;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
}

.headingBlock {
  margin-top: 40px;
  margin-bottom: 10px;
  padding-left: 10px;

  h1 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: global.$primary;
  }

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: global.$blogTextColor;
  }
}

.browseBlockBase {
  $bodyBg: rgba(64, 79, 91, 0.05);
  margin-bottom: 40px;

  .contentHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include global.touch {
      flex-direction: column;
    }

    .ptTabs {
      display: flex;
      align-items: center;
      user-select: none;
      max-width: calc(100% - 300px);
      overflow-x: auto;
      @include global.touch {
        max-width: unset;
        width: 100%;
      }

      .ptTab {
        padding: 15px 20px;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
        white-space: nowrap;
        border-radius: 12px 12px 0px 0px;

        &::first-letter {
          text-transform: capitalize;
        }

        &.active {
          color: global.$primary;
          font-weight: 700;
          background: $bodyBg;
        }
      }
    }

    .cityButtons {
      display: flex;
      align-items: center;
      user-select: none;
      background: $bodyBg;
      border-radius: 12px 12px 0px 0px;
      padding: 15px 15px;
      padding-bottom: 10px;
      max-width: calc(100% - 300px);
      overflow-x: auto;
      @include global.touch {
        max-width: unset;
        width: 100%;
      }

      .cityButton {
        padding: 15px 20px;
        padding-bottom: 10px;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
        white-space: nowrap;
        border-radius: 12px 12px 0px 0px;
        font-size: 14px;
        padding: 5px 15px;
        background: white;
        border-radius: 3px;
        margin-right: 10px;

        &:last-of-type {
          margin-right: 0px;
        }

        &::first-letter {
          text-transform: capitalize;
        }

        &.active {
          font-weight: 700;
          background: global.$primary;
          color: white;
        }
      }
    }

    .search {
      padding: 15px;
      padding-bottom: 0px;
      background: $bodyBg;
      border-radius: 12px 12px 0px 0px;
      @include global.touch {
        width: 100%;
        border-radius: 0px;
      }
    }
  }

  .contentBody {
    border-radius: 0px 0px 13px 13px;
    width: 100%;
    padding: 15px;
    position: relative;
    background: $bodyBg;

    p {
      font-weight: 600;
      font-size: 18px;
      line-height: 160%;
      color: global.$blogTextColor;
      margin-bottom: 1rem;
    }

    button {
      margin-bottom: 1rem;
    }

    h2 {
      font-weight: 700;
      font-size: 20px;
      line-height: 160%;
      color: global.$primary;
      margin-bottom: 5px;
    }
  }
}

.testimonials {
  background: url("~@/assets/img/lading-hero-c.jpg") center center;
  background-size: cover;
}

.adviceBlock {
  margin-top: 40px;
  margin-bottom: 24px;
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;

  .advices {
    grid-column: span 2;
    max-width: 800px;
    @include global.touch {
      grid-column: span 3;
      max-width: unset;
    }

    .advice {
      .adviceBlockTitle {
        font-family: "Nunito";
        font-weight: 700;
        font-size: 32px;
        color: global.$blogTextColorLight;
      }
    }
  }

  .sideBlocks {
    order: 0;
    position: relative;
    @include global.touch {
      display: none;
    }

    .floater {
      position: relative;
      position: sticky;
      top: 1rem;
      display: grid;
      gap: 1rem;
    }
  }
}

.noAdviceContentBlock {
  width: 100%;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  margin-bottom: 20px;
}
</style>
