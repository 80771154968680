<template>
  <SideBlock class="QuickOfferBox" title="Nincs időd böngészni?">
    <p class="sideBlockText">
      Ha nem akarsz telefonálgatni, adj fel egy hirdetést, és hagyd hogy
      szakembereink találjanak meg Téged!
    </p>
    <TenderSteps class="offerSteps mb-5" />

    <div v-if="textInput" class="tender-text">
      <p class="mb-1">Írd le, mire van szükséged!</p>
      <textarea
        v-model="tenderText"
        class="textarea"
        placeholder="Például: „20 nm fal festése...”"
        type="text"
      />
    </div>

    <div class="sideBlockCtas">
      <b-button type="is-info" class="is-fullwidth" @click="requestTender()">
        Hirdetésfeladás
      </b-button>
    </div>
  </SideBlock>
</template>

<script>
import TenderSteps from "~/components/_refactored/img/TenderSteps.vue";
import SideBlock from "~/components/_refactored/mainpage/SideBlock.vue";

export default {
  components: { SideBlock, TenderSteps },
  props: {
    isBlog: {
      type: Boolean,
      default: false,
    },
    textInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tenderText: "",
    };
  },
  methods: {
    requestTender() {
      this.$track.analyticsEvent({
        category: "gyorsitott",
        action: "kattintas-ajanlatkeresre",
      });

      this.$track.analyticsEvent({
        category: "quick-tender",
        action: "click",
      });

      this.$emit("requestTender", this.tenderText);
    },
  },
};
</script>

<style lang="scss" scoped>
@use "styles/_all-members" as global;

.QuickOfferBox {
  .offerSteps {
    max-height: 80px;
    margin: 0px auto;
  }

  .tender-text {
    color: global.$blogTextColor;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;

    .textarea {
      width: 100%;
      height: 80px;
      min-height: 20px;
      background: rgba(white, 0.2);
      border: 1px solid rgba(black, 0.2);
      box-shadow: none !important;
      outline: none !important;
    }
  }
}
</style>
