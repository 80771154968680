<template>
  <div
    class="swiper-holder [&::-webkit-scrollbar-thumb]:bg-primary [&::-webkit-scrollbar-thumb]:rounded-md"
  >
    <div ref="peopleSwiper" class="swiper-main">
      <slot />
    </div>
    <div class="scrollButton text-primary right" @click="scrollOne(1)">
      <IconComponent icon="angle-double-right" />
    </div>
    <div class="scrollButton text-primary left" @click="scrollOne(-1)">
      <IconComponent icon="angle-double-left" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    scrollOne(direction) {
      const element = this.$refs.peopleSwiper;
      const contentWidth = this.$refs.peopleSwiper?.childNodes[0]?.clientWidth;
      if (contentWidth) {
        element.scrollLeft += direction * contentWidth;
      } else {
        element.scrollLeft += direction * 400;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "styles/_all-members" as global;
// .swiper-main{
//   height: 250px!important;
// }

.swiper-holder {
  position: relative;

  // @include global.mobile {
  //   padding: 0px 10px;
  // }

  .swiper-main {
    padding-bottom: 20px;
    padding-top: 10px;
    display: flex;
    gap: 15px;
    overflow-x: auto;
    overflow-y: visible;
    // align-items: flex-start;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;

    * {
      scroll-snap-align: start;
    }

    .swiper-slide {
      flex-grow: 1;
      // height: auto;
      scroll-snap-align: start;
      margin: 0px 5px;

      &:first-of-type {
        margin-left: 0px;
      }

      &:last-of-type {
        margin-right: 0px;
      }
    }

    /* width */
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .scrollButton {
    width: 30px;
    height: 30px;
    font-size: 14px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    box-shadow: 0px 5px 10px rgba(black, 0.1);
    transition: all 0.2s;
    cursor: pointer;

    @include global.mobile {
      display: none;
    }

    &:hover {
      box-shadow: 0px 2px 2px rgba(black, 0.1);
    }

    &:active {
      box-shadow: 0px 0px 2px rgba(black, 0.1);
    }

    &.left {
      right: 100%;
      transform: translateY(-50%) translateX(50%);
    }

    &.right {
      left: 100%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
}
</style>
