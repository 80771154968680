<template>
  <div v-if="regionsWhichHasProfessional" class="cityTabs">
    <div class="regionTabs">
      <div
        v-for="(region, index) in regionsWhichHasProfessional"
        :key="index"
        class="regionTab"
        :class="{
          active: selectedRegionIndex === index,
        }"
        @click="selectedRegionIndex = index"
      >
        {{ region }}
      </div>
    </div>
    <div
      v-for="(regionList, index) in regionsWhichHasProfessional"
      :key="index"
      class="cityGrid"
    >
      <div class="content" :class="{ visible: selectedRegionIndex == index }">
        <NuxtLink
          v-for="(city, index2) in citiesBySelectedRegion(index)"
          :key="index2"
          :to="
            isException
              ? `/szakemberek/${profession.seoName}/${city.seoName}`
              : `/${city.seoName}`
          "
        >
          {{ city.name }}
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    counties: {
      type: Array,
      required: true,
    },
    cityPersonCount: {
      type: Array,
      required: true,
    },
    profession: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedRegionIndex: 0,
    };
  },
  computed: {
    isException() {
      return this.$helpers.is301(this.profession);
    },
    countiesMap() {
      return new Map(this.counties.map((c) => [c.id, c]));
    },
    // citiesBySelectedRegion () {
    //   const selectedRegion = this.regionsWhichHasProfessional[this.selectedRegionIndex]
    //   const selectedCounties = this.counties.filter((county) => county.region === selectedRegion)
    //   const selectedCountiesCityIds = selectedCounties.map((county) => county.cities.map((city) => city.id)).flat()
    //   return this.cityPersonCount.filter((city) => selectedCountiesCityIds.includes(city.id) && city.population > 10)
    // },
    regionsWhichHasProfessional() {
      if (!this.counties?.length || !this.cityPersonCount?.length) {
        return [];
      }
      const regions = [];
      const countyIdsWithProfessional = [];
      for (const city of this.cityPersonCount) {
        if (
          city.population > 0 &&
          !countyIdsWithProfessional.includes(city.countyId)
        ) {
          countyIdsWithProfessional.push(city.countyId);
        }
        if (countyIdsWithProfessional.length === this.counties.length) {
          break;
        }
      }
      for (const countyId of countyIdsWithProfessional) {
        const county = this.countiesMap.get(countyId);
        if (!regions.includes(county.region)) {
          regions.push(county.region);
        }
      }

      return regions;
    },
  },
  methods: {
    citiesBySelectedRegion(index) {
      const selectedRegion = this.regionsWhichHasProfessional[index];
      const selectedCountyIds = this.counties
        .filter((county) => county.region === selectedRegion)
        .map((c) => c.id);
      return this.cityPersonCount.filter(
        (city) =>
          city.population > 10 && selectedCountyIds.includes(city.countyId)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@use "styles/_all-members" as global;

.regionTabs {
  display: flex;
  overflow-x: auto;
  justify-content: flex-start;
  width: 100%;
  text-align: center;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(rgb(194, 194, 194), 0.2);
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(rgb(194, 194, 194), 0.5);
  }

  .regionTab {
    padding: 15px 30px;
    white-space: nowrap;
    cursor: pointer;
    flex-grow: 1;
    border-bottom: 3px solid rgba(rgb(194, 194, 194), 0.5);

    &.active {
      color: global.$primary;
      font-weight: 700;
      border-bottom: 3px solid rgba(global.$primary, 1);
    }
  }
}
.cityGrid {
  .content {
    display: none;
    &.visible {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      padding: 15px 15px;
    }
  }

  a {
    color: black;
    box-sizing: border-box;
    padding: 5px 15px;
    &:hover {
      color: global.$primary;
    }
  }
}
</style>
