<template>
  <b-autocomplete
    ref="professionTaskAutocomplete"
    v-model="professionTaskFilter"
    icon-pack="fas"
    icon="briefcase"
    :data="filteredProfessionTasks"
    open-on-focus
    field="name"
    :placeholder="placeholder"
    :keep-first="true"
    v-bind="$attrs"
    @focus="onFocus"
    @select="select"
  >
    <template #empty>
      <span>
        {{ $t("professionAutocomplete.empty") }}
      </span>
    </template>
  </b-autocomplete>
</template>

<script>
const KEYWORD = "keyword";

export default {
  props: {
    options: {
      type: Array,
      default: null,
    },
    prefill: {
      type: Object,
      default: null,
    },
    keywordSearch: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      professionTaskFilter: "",
      focused: false,
    };
  },
  computed: {
    professionTasks() {
      return this.focused ? this.options : [];
    },
    normalizedProfessionTaskFilter() {
      return this.$helpers.normalizeInput(this.professionTaskFilter);
    },
    filteredProfessionTasks() {
      if (!this.professionTaskFilter) {
        return this.professionTasks;
      }
      const rankedList = [];
      for (const p of this.professionTasks) {
        const index = this.$helpers
          .normalizeInput(p.name)
          .indexOf(this.normalizedProfessionTaskFilter);
        if (index > -1) {
          rankedList.push({
            index,
            professionTask: p,
          });
        }
      }
      rankedList.sort((a, b) => {
        if (a.index === b.index) {
          return a.professionTask.seoName > b.professionTask.seoName ? 1 : -1;
        }
        return a.index > b.index ? 1 : -1;
      });
      const result = rankedList.map((x) => x.professionTask);
      return result;
    },
  },
  mounted() {
    this.setSelected(this.prefill);
  },
  methods: {
    setSelected(professionTask) {
      if (!professionTask) {
        return;
      }
      if (
        this.professionTaskFilter &&
        professionTask.name === this.professionTaskFilter
      ) {
        return;
      }
      this.$refs.professionTaskAutocomplete.setSelected(professionTask);
    },
    onFocus() {
      this.focused = true;
      this.$emit("focus");
    },
    select(professionTask) {
      let toEmit = professionTask;
      if (professionTask && professionTask.id === KEYWORD) {
        // Emit keyword instead of hacky object
        toEmit = professionTask.name;
      }

      this.$emit("select", toEmit);
    },
    blur() {
      this.$refs.professionTaskAutocomplete.isActive = false;
    },
  },
};
</script>
